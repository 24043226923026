//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {bgimgUrl2} from '/src/utils/config.js'
import {
	getInfo,
	getRankInfo,
	schoolteacherRank,
	schoolParentRank,
	parentRank,
	teacherRank,
} from '../../api'
export default {
	name: "index",
	data() {
		return {
			bgimgUrl2:'',
			activeIndex: 0,
			schoolteacherRank: [], // 学校老师/家长总排名
			// schoolParentRank:[], //学校老师总排名
			rankInfo: [], //家长/老师排名
			rank: '',
			position: '',
			totaltime: '',


			dataFrom: [],
			school_rank: [],
			username: '',
			flag: true
		}
	},
	created() {
		this.bgimgUrl2 = bgimgUrl2
		// if (this.$route.query.id == '1') {
		// 	this.activeIndex = 0
		// } else if (this.$route.query.id == '2') {
		// 	this.activeIndex = 1
		// } else {
		// 	this.activeIndex = 0
		// }

		// if (localStorage.getItem('token') != null) {
		// 	this.get_schoolteacherRank()
		// 	// this.get_Info()
		// 	this.flag = true
		// } else {
		// 	this.flag = false
		// }
		this.get_schoolteacherRank()
	},
	methods: {
		//学校教师排名
		get_schoolteacherRank() {
			schoolteacherRank().then(res => {
				console.log(res.data.data)
				this.schoolteacherRank = res.data.data
			})
		},
		//学校家长排名
		get_schoolParentRank() {
			schoolParentRank().then(res => {
				this.schoolteacherRank = res.data.data
				console.log(res.data.data)
			})
		},
		//家长排名
		get_parentRank() {
			parentRank().then(res => {
				console.log(res.data.data.point_rank[0].rank)
				//排名
				this.rank = res.data.data.point_rank[0].rank
				//分数
				this.position = res.data.data.point_rank[0].points
				//时间
				this.totaltime = res.data.data.point_rank[0].totaltime
				this.rankInfo = res.data.data.point_rank[0].rankInfo
			})
		},
		//老师排名
		get_teacherRank() {
			teacherRank().then(res => {
				console.log(res.data.data.point_rank[0].rank)
				//排名
				this.rank = res.data.data.point_rank[0].rank
				//分数
				this.position = res.data.data.point_rank[0].points
				//时间
				this.totaltime = res.data.data.point_rank[0].totaltime
				this.rankInfo = res.data.data.point_rank[0].rankInfo
				console.log(res.data.data.point_rank[0].rankInfo)
			})
		},
		// get_RankInfo() {
		// 	getRankInfo().then(res => {
		// 		console.log(res)
		// 		this.dataFrom = res.data.data.point_rank
		// 		this.school_rank = res.data.data.school_rank
		// 	})
		// },
		handleClick() {
			this.$router.go(-1)
		},
		activeClick(index) {
			this.activeIndex = index
			if (index == 0) {
				this.get_schoolteacherRank()
			} else if (index == 1) {
				this.get_schoolParentRank()
			} else if (index == 2) {
				this.get_parentRank()
			} else if (index == 3) {
				this.get_teacherRank()
			}
		},
		mingcheng(e) {
			return e.substring(0, 1) + new Array(e.length).join('*')
		},
		s_to_hs(s) {
			//计算分钟
			//算法：将秒数除以60，然后下舍入，既得到分钟数
			let h
			h = Math.floor(s / 60)
			//计算秒
			//算法：取得秒%60的余数，既得到秒数
			s = s % 60
			//将变量转换为字符串
			h += ''
			s += ''
			//如果只有一位数，前面增加一个0
			h = (h.length == 1) ? '0' + h : h
			s = (s.length == 1) ? '0' + s : s
			return h + ':' + s
		},

		get_Info() {
			getInfo({
				token: localStorage.getItem('token')
			}).then(res => {
				console.log(res)
				this.username = res.data.data.username
			})
		}
	}
}
